import { STATUS } from 'configs/localData';
import { nilList } from 'constants/common';
import CryptoJS from 'crypto-js';
import _, { isEmpty, reduce } from 'lodash';
import queryString from 'query-string';

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRIPT_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRIPT_KEY);

const parseJSON = (string) => {
  if (string) {
    try {
      return JSON.parse(string);
    } catch (err) {
      return string;
    }
  }
  return string;
};

export const getSearch = (filter) => {
  const params = {
    limit: filter?.limit,
    offset: filter?.offset,
    q: filter?.q,
    orderBy: filter?.orderBy,
    ...getValidData(filter?.filter),
  };

  return convertObjToSearchStr(params);
};

export const downloadImg = async ({ url, fileName }) => {
  fetch(url, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName || url);
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(() => {
      //
    });
};

export const convertObjToSearchStr = (params, acceptNull) =>
  Object.keys(params)
    .map((key) =>
      params[key] !== undefined && (acceptNull || params[key] !== null)
        ? `${encodeURIComponent(key)}=${encodeURIComponent(
            JSON.stringify(params[key]),
          )}`
        : '',
    )
    .filter((data) => data !== '')
    .join('&');

const getValidDataOfObj = (obj, isFilter) => {
  const validData = reduce(
    obj,
    (result, value, key) => {
      if (Array.isArray(value)) {
        return value.length === 0 && isFilter
          ? result
          : { ...result, [key]: value };
      }
      if (typeof value === 'object' && !isEmpty(value)) {
        const formatChildValue = getValidDataOfObj(value);
        return !isEmpty(formatChildValue)
          ? { ...result, [key]: formatChildValue }
          : result;
      }

      if (value || value === false || value === 0) {
        // eslint-disable-next-line
        result[key] = value;
        return { ...result, [key]: value };
      }

      if (value === '' && !isFilter) {
        // eslint-disable-next-line
        result[key] = '';
      }
      return result;
    },
    {},
  );
  return validData;
};

export const getValidData = (filter, isFilter) =>
  getValidDataOfObj(filter, isFilter);

export const getFilterFromUrl = (searchStr) => {
  const parsed = {};
  if (!searchStr || searchStr.trim() === '') return {};
  decodeURIComponent(searchStr)
    .trim()
    .substring(searchStr?.[0] === '?' ? 1 : 0)
    .split('&')
    .forEach((text) => {
      const keyValue = text.split('=');
      // eslint-disable-next-line
      parsed[keyValue[0]] = decodeURIComponent(keyValue[1]);
      try {
        parsed[keyValue[0]] = JSON.parse(parsed[keyValue[0]]);
      } catch (error) {
        // eslint-disable-next-line
        parsed[keyValue[0]] = parsed[keyValue[0]];
      }
    });
  return parsed;
};

export const queryStringFromUrl = (searchString, hasURI = false) => {
  const temp = {};
  const obj = queryString.parse(searchString, {
    parseNumbers: true,
    parseBooleans: true,
    parseFragmentIdentifier: true,
  });

  // eslint-disable-next-line consistent-return
  Object.keys(obj).forEach((key) => {
    if (nilList.includes(obj[key])) return true;

    const hasString =
      typeof obj[key] === 'string' &&
      obj[key]?.indexOf('[') === -1 &&
      obj[key]?.indexOf('{') === -1;

    if (hasString) {
      const replaceQuote = obj[key].replace(/["]+/g, '');
      temp[key] = !hasURI
        ? replaceQuote
        : replaceQuote.replace(/[&]+/g, '%26').replace(/[+]+/g, '%2B');
    } else {
      temp[key] = parseJSON(obj[key]);
    }
  });

  return temp;
};

export const replaceAll = (str, search, replacement) =>
  str.replace(new RegExp(search, 'g'), replacement);

export const makeBreadCrumbFromPath = (location) => {
  const BREADCRUMB_LIST = [];
  const paths = location.pathname.split('/');
  paths.forEach((data) => {
    if (data === '') return;
    BREADCRUMB_LIST.push({
      title: data,
      path: `${
        BREADCRUMB_LIST.length
          ? BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].path
          : ''
      }/${data}`,
    });
  });
  return BREADCRUMB_LIST;
};

export const getImageUrl = (image = '') =>
  (image && (image.indexOf('http') > -1 || image.indexOf('https') > -1)) ||
  image.indexOf('data:image') > -1
    ? image
    : `${process.env.REACT_APP_IMAGE_HOST}${image}`;

export function plainToFlattenObject(object) {
  const result = {};

  function flatten(obj, prefix = '') {
    _.forEach(obj, (value, key) => {
      if (_.isObject(value) || Array.isArray(value)) {
        flatten(value, `${prefix}${key}.`);
      } else {
        result[`${prefix}${key}`] = value;
      }
    });
  }

  flatten(object);

  return result;
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const downloadImage = (previewImage, fileName = 'product.png') => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = previewImage;
  a.style = 'display: none';
  a.download = fileName;
  a.click();
  a.remove();
};

export const getPreviewImageUrl = ({
  image = '',
  configs = {},
  ratio,
  width = 400,
}) => {
  const resizeByConvert = {
    bucket: configs?.BUCKET_NAME,
    key: image,
    edits: {
      resize: {
        width,
        fit: 'cover',
      },
    },
  };

  const resizeByRatio = {
    bucket: configs?.BUCKET_NAME,
    key: image,
    edits: {
      resize: {
        ratio,
      },
    },
  };
  const objJsonStr = JSON.stringify(ratio ? resizeByRatio : resizeByConvert);
  const objJsonB64 = btoa(objJsonStr);
  return (image &&
    (image.indexOf('http') > -1 || image.indexOf('https') > -1)) ||
    image.indexOf('data:image') > -1
    ? image
    : `${configs?.CDN_URL}/${objJsonB64}`;
};

export const encryptPassword = (data) => {
  const cipher = CryptoJS.AES.encrypt(data, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return cipher.toString();
};

export const encryptText = (text) => {
  const cipher = CryptoJS.AES.encrypt(JSON.stringify(text), key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.ECB,
  });

  return cipher.toString(CryptoJS.format.Hex);
};

export const decryptText = (text) => {
  const encryptedHex = CryptoJS.enc.Hex.parse(text);
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
  const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.ECB,
  });
  const decryptedText = parseJSON(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedText;
};

export const encryptSha512Data = (data) =>
  CryptoJS.SHA512(data).toString(CryptoJS.enc.Hex);

export const formatInputMoney = {
  formatter: (value) => {
    const val = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (value.includes('.')) {
      const [beforeDot, afterDot] = val.split('.');
      const afterDotTrimmed = afterDot?.slice(0, 2);
      return `${beforeDot}.${afterDotTrimmed}`;
    }
    return val;
  },
  parser: (value) => {
    const val = value.replace(/\\s?|(,*)/g, '');
    if (value.includes('.')) {
      const [beforeDot, afterDot] = val.split('.');
      const afterDotTrimmed = afterDot?.slice(0, 2);
      return `${beforeDot}.${afterDotTrimmed}`;
    }
    return val;
  },
  onKeyDown: (e) => {
    if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === '.') {
      return;
    }
    if (e.nativeEvent.key < '0' || e.nativeEvent.key > '9') {
      e.preventDefault();
    }
  },
};

export const formatInputSNID = {
  parser: (value) => {
    const val = value.replace(/\\s?|(,*)/g, '');
    if (value.includes('.')) {
      const [beforeDot, afterDot] = val.split('.');
      const afterDotTrimmed = afterDot?.slice(0, 2);
      return `${beforeDot}.${afterDotTrimmed}`;
    }
    return val;
  },
  onKeyDown: (e) => {
    if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === '.') {
      return;
    }
    if (e.nativeEvent.key < '0' || e.nativeEvent.key > '9') {
      e.preventDefault();
    }
  },
};

export const downloadFile = (data, filename, hasExistBlobUrl) => {
  const blob = new Blob([data], {
    type: 'application/vnd.ms-excel',
  });
  const link = document.createElement('a');
  link.href = hasExistBlobUrl ? data : window.URL.createObjectURL(blob);
  link.download = filename;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatCurrencyUS = {
  formatter: (value) => {
    const formatterUSD = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatterUSD.format(value);
  },
  parser: (value) => {
    const formatterUSD = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatterUSD.format(value);
  },
  onKeyDown: (e) => {
    if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === '.') {
      return;
    }
    if (e.nativeEvent.key < '0' || e.nativeEvent.key > '9') {
      e.preventDefault();
    }
  },
};

export const getStatusFilterQuery = (status) => {
  switch (status) {
    case STATUS.Active:
      return true;
    case STATUS.Inactive:
      return false;
    default:
      return undefined;
  }
};

export const getStatusFilterForm = (status) => {
  switch (status) {
    case true:
      return STATUS.Active;
    case false:
      return STATUS.Inactive;
    default:
      return STATUS.All;
  }
};

export const genEVoucherPaymentAmount = (eVoucher) => {
  const balance = Number(eVoucher?.balance);
  const denomination = Number(eVoucher?.denomination);

  let total = 0;
  let paymentAmounts = [0];

  while (total < balance) {
    const nextPaymentAmount = total + denomination;
    if (nextPaymentAmount <= balance) {
      paymentAmounts = [...paymentAmounts, nextPaymentAmount];
    }
    total = nextPaymentAmount;
  }

  if (total > balance) paymentAmounts.push(balance);

  return paymentAmounts;
};

export const getSorter = (field) => (a, b) => {
  if (a?.[field] > b?.[field]) return 1;
  if (a?.[field] < b?.[field]) return -1;
  return 0;
};
